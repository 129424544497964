/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a",
    strong: "strong",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://pixabay.com/users/publicdomainpictures-14"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://edu.goorm.io/lecture/37330/re-commit-%EC%84%B1%EA%B3%B5%EC%A0%81%EC%9C%BC%EB%A1%9C-%EC%8B%A0%EA%B8%B0%EC%88%A0%EC%9D%84-%EB%8F%84%EC%9E%85%ED%95%98%EB%8A%94-%EB%B2%95-%EA%B9%80%EC%B6%A9%EC%84%AD-cto"
  }, "subicura 님 COMMIT 발표")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Ruby 프로그래밍 언어 30주년"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20230223085800002"
  }, "로톡 과징금 20억원")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.blog/2023-03-01-github-actions-importer-is-now-generally-available/"
  }, "깃헙 액션 Importer GA")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://dagger.io/"
  }, "dagger")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://opensource.googleblog.com/2023/03/introducing-service-weaver-framework-for-writing-distributed-applications.html"
  }, "Service Weaver")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Datadog 장애"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "3월 8일 15:31분 장애 시작(EST 1:31)"), "\n", React.createElement(_components.li, null, "20:20 처음 ", React.createElement(_components.strong, null, "Identified"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "We are seeing reduced error rates for the web application. We are continuing to work on mitigating and investigating the issue causing delayed data ingestion across all data types. Monitor notifications are delayed, and you may observe delayed data throughout the app."), "\n"), "\n"), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
